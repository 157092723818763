.spinner{
    display: flex;
    justify-content: center;
}

.spinning{
    animation: spinner 1s infinite ;
}

@keyframes spinner{
    to{
        transform: rotate(360deg);
    }
}