

.dropzone {
	width: 100%;
	height: 130px;
	border: 1px dashed #999;
	border-radius: 3px;
	text-align: center;
}

 

.upload-input {
	position: relative;
	top: -110px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}