
.aggEmpBtn:disabled{
    background: rgba(240, 238, 238, 0.945);
    color: #313336;
    cursor: not-allowed;
    border:none;
}

.campo{
    text-align: start;
}