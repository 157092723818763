.error {
	color: #e92b2d;
	font-weight: 600;
	font-size: 12px;
}

.input{
	border-color: darkgray;
}

.input:focus,
.input:hover{
	border-color:#3c5ebb ;
}

.btn-verde:hover{
	background-color: #90EE90 !important;
}
.btn-rojo:hover{
	background-color: #FE6F5E!important;
}