.iframeContent {
  position: relative;
  padding-bottom: 49.25%;
  height: 100%;
  overflow: hidden;
}
.iframeContent iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
}

::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    border-radius: 10px;
    background-color: #CCCCCC;
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #D62929;
    background-image: -webkit-linear-gradient(90deg,transparent,rgba(0, 0, 0, 0.4) 50%,transparent,transparent)
}