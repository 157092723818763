

.customDatePickerWidth > div {
    display: inherit !important;
}


.customHeightWidth > div.css-b62m3t-container > div.css-1s2u09g-control {
    height: calc(1.5em + .75rem + 2px) !important;
    min-height: inherit !important;
}
/* border: 1px solid #ced4da; */
