*{
  margin: 0;
  padding: 0;
}

.rdt_TableCol {
  justify-content: center;
}

.flex-1{
  flex: 1;
}

.d-gap-30 {
  gap: 10px;
}

.css-qc6sy-singleValue{
  margin-top: -5px;
}