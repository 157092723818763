
.btn-azul:hover{
   background-color: #ADD8E6 !important;
}

.customDatePickerWidth > div {
    display: inherit !important;
}


.tt{
    font-size : 16px;
    font-weight : 600;
}