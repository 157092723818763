.container-fluid{
  max-width: 1250px !important;
}


#wrapper {
  display: flex;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}
#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}
