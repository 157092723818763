
.customDatePickerWidth > div {
    display: inherit !important;
}

.label-form{ 
    font-size: 15px;
    font-weight: 700; 
    padding: 10px 
}

.encabezado-form{
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
}